<div class="container root">
  <div class="row">
    <div class="col-12 col-sm-8 offset-sm-2">
      <h1>Assets</h1>
      <ul>
        <li class="dir" *ngFor="let subDir of subDirs" [routerLink]="[generateLink(subDir)]">
          {{subDir}}
        </li>
        <li class="file" *ngFor="let file of files" (click)="download(file.url)">{{file.name}}</li>
      </ul>
    </div>
  </div>
</div>
